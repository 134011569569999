<template>
  <div class="newForecastCard has-gap-5" v-if="isFeatureFlagOn">
    <ProjectCollapse id="project-focus">
      <template #header>
        <h3 class="title">
          {{ $tf("projectPage.focus.title|Dolgozói fókusz") }}
          <b-tooltip type="is-info">
            <template v-slot:content>
              <div class="content tooltip">
                <p>
                  {{
                    $tf(
                      'projectPage.focus.info|Információ a projektben résztvevő dolgozók "fókuszáról", azaz arról, hogy hány projekten dolgoznak egyszerre.'
                    )
                  }}
                </p>
              </div>
            </template>
            <b-icon icon="info-circle" />
          </b-tooltip>
        </h3>
      </template>
      <div class="is-flex is-flex-direction-column">
        <div class="is-flex has-gap-5 m-4">
          <div>
            {{ $tf("projectPage.focus.fromDate|Ettől:") }}
            <f-datepicker
              v-model="fromDate"
              :min-date="new Date(2010, 0)"
              :max-date="toDate"
            />
          </div>
          <div>
            {{ $tf("projectPage.focus.fromDate|Eddig:") }}
            <f-datepicker
              v-model="toDate"
              :min-date="fromDate"
              :max-date="new Date()"
            />
          </div>
        </div>
        <b-table
          :data="focus.employees"
          :loading="isLoading"
          :default-sort="['userData', 'desc']"
          hoverable
          striped
        >
          <b-table-column
            field="userData"
            :label="$tf('projectPage.focus.employee|Dolgozó')"
            sortable
            :custom-sort="
              (u1, u2, asc) => u1.userData.name > u2.userData.name !== asc
            "
            v-slot="props"
          >
            <UserInfo
              :user="props.row.userData"
              display-mode="long"
              tooltipPosition="right"
            />
          </b-table-column>
          <b-table-column
            field="totalProjects"
            :label="$tf('projectPage.focus.totalProjects|Összes projekt')"
            sortable
            numeric
            v-slot="props"
          >
            {{ props.row.totalProjects }}
          </b-table-column>
          <b-table-column
            field="weeklyAverageProjects"
            :label="$tf('projectPage.focus.weeklyProjects|Heti átlag')"
            sortable
            numeric
            v-slot="props"
          >
            <b-tooltip :label="props.row.weeklyAverageProjects.toString()">
              {{ fmtFloat(props.row.weeklyAverageProjects) }}
            </b-tooltip>
          </b-table-column>
          <b-table-column
            field="dailyAverageProjects"
            :label="$tf('projectPage.focus.dailyAverage|Napi átlag')"
            sortable
            numeric
            v-slot="props"
          >
            <b-tooltip :label="props.row.dailyAverageProjects.toString()">
              {{ fmtFloat(props.row.dailyAverageProjects) }}
            </b-tooltip>
          </b-table-column>
          <template #empty>
            <div class="has-text-centered">
              {{ $tf("projectPage.focus.noRecords|Nincs adat") }}
            </div>
          </template>
        </b-table>
      </div>
    </ProjectCollapse>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import UserInfo from "@/components/module/info/UserInfo.vue";
import dayjs from "dayjs";
import ProjectCollapse from "@/components/enterprise/project/module/ProjectCollapse.vue";
import FDatepicker from "@/components/module/input/FDatepicker.vue";
import { FEATURE_FLAGS } from "@/utils/featureFlags";

export default {
  name: "ProjectFocus",
  components: {
    FDatepicker,
    ProjectCollapse,
    UserInfo,
  },
  props: {
    projectId: {
      type: Number,
      required: true,
    },
  },
  async mounted() {
    await this.updateStatistics();
  },
  watch: {
    async fromDate() {
      await this.updateStatistics();
    },
    async toDate() {
      await this.updateStatistics();
    },
  },
  data() {
    return {
      fromDate: dayjs().month(0).date(1).toDate(),
      toDate: new Date(),
      isLoading: false,
      formatter: Intl.NumberFormat(undefined, {
        maximumFractionDigits: 1,
      }),
    };
  },
  computed: {
    ...mapGetters({
      focus: "focus/focusByProject",
      isFeatureFlagOnGetter: "env/isFeatureFlagOn",
    }),
    isFeatureFlagOn() {
      return this.isFeatureFlagOnGetter(FEATURE_FLAGS.insights.focus);
    },
  },
  methods: {
    fmtFloat(n) {
      return this.formatter.format(n);
    },
    async updateStatistics() {
      if (!this.isFeatureFlagOn) return;
      this.isLoading = true;
      await this.$store.dispatch("focus/fetchFocusByProject", {
        params: {
          from: this.fromDate.format("YYYY-MM-DD"),
          to: this.toDate.format("YYYY-MM-DD"),
          projectId: this.projectId,
        },
      });
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
